<template>
<section class="container testimonial-block">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10 col-xl-8 text-center">
      <h3 class="mb-4">C'est vous qui le dites</h3>
      <p class="mb-4 pb-2 mb-md-5 pb-md-0">
        Parce que la satisfaction des professeurs est notre priorité, nous portons un grand intérêt à vos retours.
      </p>
    </div>
  </div>

  <div class="row text-center">
    <TestimonialCard
    v-for="card in cards"
    :key="card.name"
    :name="card.name"
    :job="card.job"
    :resume="card.resume"
    :photo="card.photo"
    />
  </div>
</section>
</template>

<script>
import TestimonialCard from './TestimonialCard.vue'

export default {
  name: 'App',
  components: {
    TestimonialCard
  },
  data() {
    return {
      cards: [
        { name: "Philippe", job: "Professeur de guitare",  photo: "Philippe.png", resume: "Grâce à EasyProf j'ai pu augmenter mes revenus et me mettre à mon compte en toute simplicité. Merci à vous pour l'aide personnalisée que vous m'apportez." },
        { name: "Camille", job: "Professeur d'anglais",  photo: "Camille.png", resume: "Merci à toute l'équipe, vous êtes super. J'avais peur de m'y mettre, mais finalement tout s'est bien passé, grâce à vous." },
        { name: "Bernard", job: "Professeur de Mathématique", photo: "Bernard.png", resume: "A 57 ans j'ai créé ma propre entreprise, mieux vaut tard que jamais comme on dit. Mes revenus ont augmentés, et surtout je suis passé du bon côté de la loi pour les revenus de mes cours." }
      ]
    }
  }
}
</script>

<style>
.testimonial-block{
    background-color:#eee;
    padding: 5%;
    margin-bottom: 50px;
    border-radius: 25% 10%;
}
</style>