<template>
    <div class="col-md-4 mb-5 mb-md-0">
        <div class="testimonial-card">
            <div class="d-flex justify-content-center mb-4">
                <img v-bind:src="require('../../assets/' + photo)" class="rounded-circle shadow-1-strong" width="150" height="150" />
            </div>
            <h5 class="mb-3">{{ name }}</h5>
            <h6 class="text-primary mb-3">{{ job }}</h6>
            <p class="px-xl-3">
                <font-awesome-icon class="pe-2" :icon="['fas', 'quote-left']" /> {{ resume }}
            </p>
            <ul class="list-unstyled d-flex justify-content-center mb-0">
                <li>
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                </li>
                <li>
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                </li>
                <li>
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                </li>
                <li>
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                </li>
                <li>
                <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  props: ['photo','name','job','resume']
}
</script>

<style>
.testimonial-card {
    background-color: white;
    padding: 5px;
    border-radius: 50% 20% / 10% 40%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>