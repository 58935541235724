<template>
    <div class="card mb-3">
    <div class="card-header" id="headingOne">
        <h5 class="mb-0">
            <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span class="text-theme-secondary me-2">Q.</span> {{ question }}</button>
        </h5>
    </div>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
            {{ answer }}
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: ['question','answer']
}
</script>