<template>
  <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal"><router-link to="/">EasyProf</router-link></h5>
    <nav class="my-2 my-md-0 mr-md-3">
      <router-link class="p-2 text-dark" to="/featuring">Offres</router-link>
      <router-link class="p-2 text-dark" to="/faq">FAQ</router-link>
    </nav>
    <router-link class="btn btn-outline-primary mr-1" to="/register">S'inscrire</router-link>
    <router-link class="btn btn-outline-primary ml-1" to="/login">Se connecter</router-link>
  </div>
</template>

<script>

</script>

<style>
/* General header CSS */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Avatar and dropdown menu CSS */
.nav img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: -8px 0;
  float: left;
  margin-right: 10px;
}

.navbar ul li i {
  font-size: 18px;
}

.navbar .dropdown-menu i {
  font-size: 16px;
  min-width: 22px;
}

.navbar .dropdown.open>a {
  background: none !important;
}

.navbar .dropdown-menu {
  border-radius: 1px;
  border-color: #e5e5e5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .05);
}

.navbar .dropdown-menu li a {
  color: #777;
  padding: 8px 20px;
  line-height: normal;
}

.navbar .dropdown-menu li a:hover,
.navbar .dropdown-menu li a:active {
  color: #333;
}

.navbar .dropdown-menu .material-icons {
  font-size: 21px;
  line-height: 16px;
  vertical-align: middle;
  margin-top: -2px;
}

.navbar .active a,
.navbar .active a:hover,
.navbar .active a:focus {
  background: transparent !important;
}

@media (min-width: 1200px) {
  .form-inline .input-group {
    width: 300px;
    margin-left: 30px;
  }
}

@media (max-width: 1199px) {
  .form-inline {
    display: block;
    margin-bottom: 10px;
  }

  .input-group {
    width: 100%;
  }
}
</style>


