<template>
    <DefaultHeader/>
    <div class="py-5 service-26">
    <div class="container">
        <div class="row wrap-service-26">
            <div class="col-md-7 align-self-center">
                <div class="max-box"> 
										<span class="badge badge-info rounded-pill px-3 py-1 font-weight-light">Service 26</span>
                    <h3 class="mt-3">Augmenter ses revenus de professeur à domicile </h3>
                    <p class="mt-3">You can relay on our amazing features list and also our customer services will be greatexperience for you without doubt and in no-time and with great quality for design.</p>
                    <p>We guarantee you with our WrapKit that you will make your site in record time and it will be fun to work on.</p>
                    <a href="javascript:void(0)" class="linking">Learn More</a>
                </div>
            </div>
            <div class="col-md-5 col-md-5"> 
							<img v-bind:src="require('../assets/money.png')" class="img-fluid" /> 
						</div>
        </div>
        <div class="row wrap-service-26 mt-4 pt-3">
            <div class="col-md-6"> 
							<img v-bind:src="require('../assets/free.jpg')" class="img-fluid" /> 
						</div>
            <div class="col-md-6 align-self-center">
                <span class="badge badge-info rounded-pill px-3 py-1 font-weight-light">Service 26</span>
                <h3 class="mt-3">Garder le contrôle de son activité</h3>
                <p class="mt-3">You can relay on our amazing features list and also our customer services will be greatexperience for you without doubt and in no-time and with great quality for design.</p>
                <p>We guarantee you with our WrapKit that you will make your site in record time and it will be fun to work on.</p>
                <a class="linking" href="javascript:void(0)">Learn More</a>
            </div>
        </div>
        <div class="row wrap-service-26">
            <div class="col-md-7 align-self-center">
                <div class="max-box"> 
										<span class="badge badge-info rounded-pill px-3 py-1 font-weight-light">Service 26</span>
                    <h3 class="mt-3">Arrêter les galères administratives </h3>
                    <p class="mt-3">You can relay on our amazing features list and also our customer services will be greatexperience for you without doubt and in no-time and with great quality for design.</p>
                    <p>We guarantee you with our WrapKit that you will make your site in record time and it will be fun to work on.</p>
                    <a href="javascript:void(0)" class="linking">Learn More</a>
                </div>
            </div>
            <div class="col-md-5 col-md-5"> 
							<img v-bind:src="require('../assets/administration.png')" class="img-fluid" /> 
						</div>
        </div>
        <div class="row wrap-service-26 mt-4 pt-3">
            <div class="col-md-6"> 
							<img v-bind:src="require('../assets/quit.jpg')" class="img-fluid" /> 
						</div>
            <div class="col-md-6 align-self-center">
                <span class="badge badge-info rounded-pill px-3 py-1 font-weight-light">Service 26</span>
                <h3 class="mt-3">Arrêt rapide et facile en cas de besoin</h3>
                <p class="mt-3">You can relay on our amazing features list and also our customer services will be greatexperience for you without doubt and in no-time and with great quality for design.</p>
                <p>We guarantee you with our WrapKit that you will make your site in record time and it will be fun to work on.</p>
                <a class="linking" href="javascript:void(0)">Learn More</a>
            </div>
        </div>
    </div>
</div>
    <RegisterCTA/>
    <DefaultFooter/>
  </template>
  
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import RegisterCTA from '../components/RegisterCTA.vue'

export default {
  beforeCreate: function() {
      document.body.className = 'homebody';
  },
  name: 'App',
  components: {
    DefaultHeader,
    DefaultFooter,
    RegisterCTA
  }
}
</script>

<style>
@import url(//fonts.googleapis.com/css?family=Montserrat:300,500);
.service-26 {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad;
  font-weight: 300;
}

.service-26 h1, .service-26 h2, .service-26 h3, .service-26 h4, .service-26 h5, .service-26 h6 {
  color: #3e4555;
}

.service-26 .font-weight-medium {
	font-weight: 500;
}

.service-26 .badge {
	line-height: 14px;
}

.service-26 .badge-info {
	background: #188ef4;
}

.service-26 .wrap-service-26 .max-box {
  max-width: 580px;
}

.service-26 a {
	text-decoration: none;
}

.service-26 .linking {
	color: #3e4555;
}

.service-26 .linking:hover {
	color: #316ce8;
}
</style>
  