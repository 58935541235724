<template>
    <DefaultHeader />
    <div class="container">
        <div class="card row mb-4 p-2 text-center bg-primary text-white">
            <h1>Condition d'utilisation</h1>
        </div>
        <div class="row mb-2">
            <p>Les présentes Conditions Générales d’Utilisation et de Vente (ci-après « les CGU ») sont conclues entre la Société R42, Société par actions simplifiée inscrite au RCS de Paris sous le numéro XXXX, au capital de 2 euros, agissant sous sa marque «EasyProf», et «l’Utilisateur» du site internet www.easyprof.com (ci-après « le Site ») et/ou de l’application disponible en téléchargement gratuit depuis les marchés d’applications Apple et Google, personne majeure ou représentant légal au nom et pour le compte d’une personne mineure capable de contracter.
            </p>

            <p>Notre équipe d'experts chevronnés vous guide étape par étape dans la création de votre auto-entreprise. Nous
                vous aidons à remplir les formulaires administratifs, à comprendre les obligations fiscales spécifiques aux
                professeurs et à optimiser votre structure d'entreprise pour bénéficier des avantages fiscaux les plus
                avantageux.</p>
        </div>
    </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue';

import DefaultFooter from '../components/DefaultFooter.vue';

export default {
    beforeCreate: function () {
        document.body.className = 'homebody';
    },
    name: 'App',
    components: {
        DefaultHeader,
        DefaultFooter
    }
}
</script>