<template>
<div class="py-5 service-19 pb-0">
  <div class="container">
    <!-- row  -->
    <div class="row">
      <div class="col-lg-7 pt-5">
        <span class="badge badge-primary rounded-pill px-3 py-1 font-weight-light">EasyProf</span>
        <h3 class="my-3">Simplifiez votre vie de professeur</h3>
        <h6 class="subtitle font-weight-light">Grâce à EasyProf, augmentez réellement vos revenus. Simplifiez vos démarches administrative et concentrez-vous sur vos cours.</h6>
        <div class="row text-inverse">
          <div class="col-md-6 mt-3">
            <ul class="list-style-none pl-0">
              <li class="my-2"><span class="mr-2">1.</span> <span>Augmentez vos revenus</span></li>
              <li class="my-2"><span class="mr-2">2.</span> <span>Obtenez les aides d'état</span></li>
              <li class="my-2"><span class="mr-2">3.</span> <span>Gérez votre auto-entreprise</span></li>
            </ul>
          </div>
          <div class="col-md-6 mt-0 mt-md-3">
            <ul class="list-style-none pl-0">
              <li class="mb-2 mt-0 mt-md-2"><span class="mr-2">4.</span> <span>Profitez de l'avance immédiate</span></li>
              <li class="my-2"><span class="mr-2">5.</span> <span>Optimisez vos revenus</span></li>
              <li class="my-2"><span class="mr-2">6.</span> <span>Bénéficiez de nos conseils</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 my-4">
            <router-link class="btn btn-info-gradiant btn-md border-0 text-white" to="/featuring">En savoir plus</router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-5 text-center">
        <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/features/feature19/img1.jpg" alt="wrappixel" />
      </div>
    </div>
  </div>
</div>
</template>


<style>
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.service-19 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.service-19 h1,
.service-19 h2,
.service-19 h3,
.service-19 h4,
.service-19 h5,
.service-19 h6 {
  color: #3e4555;
}

.service-19 .font-weight-medium {
  font-weight: 500;
}

.service-19 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.service-19 .badge {
  line-height: 14px;
}

.service-19 .badge-primary {
  background: #316ce8;
}

.service-19 .list-style-none {
  list-style: none;
}

.service-19 .text-inverse {
  color: #3e4555 !important;
}

.service-19 .btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.service-19 .btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
  background: -webkit-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.service-19 .btn-md {
  padding: 15px 45px;
  font-size: 16px;
}
</style>