<template>
    <div class="service-16">
        <div class="row wrap-service-16 no-gutters">
            <div class="col-lg-6 with-bg spacing" v-bind:style="{ 'background-image': 'url(' + require('../../assets/prof.jpg') + ')' }"></div>
            <div class="col-lg-6 spacing bg-info-gradiant text-white">
                <div class="with-text">
                    <h3 class="text-white mb-3">EasyProf, par les profs et pour les profs</h3>
                    <p class="op-7">Les créateurs de EasyProf sont égalements des professeurs, ils ont connus toutes les galères que les professeurs particuliers connaissent. Ensemble, ils ont développé EasyProf pour permettre à tous de profiter de leur expérience.</p>
                    <ul class="list-block list-style-none pl-0 mb-3">
                        <li class="my-2"><span class="mr-2">1.</span><span>Augmentez réellement vos revenus</span></li>
                        <li class="my-2"><span class="mr-2">2.</span> <span>Obtenez les aides d'état disponible pour vous</span></li>
                        <li class="my-2"><span class="mr-2">3.</span> <span>Simplifiez toutes vos démarches administratives</span></li>
                        <li class="my-2"><span class="mr-2">4.</span> <span>Gérez facilement votre auto-entreprise</span></li>
                    </ul>
                    <router-link class="btn btn-outline-light btn-md mt-3" to="/register">S'inscrire</router-link>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <style>
  @import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
  .service-16 {
    font-family: "Montserrat", sans-serif;
      color: #ffffff;
    font-weight: 300;
    margin-bottom: 50px;
  }
  
  .service-16 h1, .service-16 h2, .service-16 h3, .service-16 h4, .service-16 h5, .service-16 h6 {
    color: #3e4555;
  }
  
  .service-16 .font-weight-medium {
      font-weight: 500;
  }
  
  .service-16 .op-7 {
      opacity: 0.7;
  }
  
  .service-16 .wrap-service-16 .spacing {
    padding: 80px 35px;
  }
  
  .service-16 .wrap-service-16 .with-bg {
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: center center;
  }
  
  .service-16 .wrap-service-16 .with-text {
    max-width: 500px;
    margin-left: 80px;
  }
  
  .service-16 .list-style-none {
      list-style: none;
  }
  
  .service-16 .bg-info-gradiant {
      background: #188ef4;
    background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
    background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
    background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
    background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
  }
  
  .service-16 .btn-md {
      padding: 15px 45px;
      font-size: 16px;
  }
  
  @media (max-width: 767px) {
          .service-16 .wrap-service-16 .with-text {
              margin-left: 0px;
      }
  }
  </style>