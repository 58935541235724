<template>
    <DefaultHeader />
    <div class="container">
        <div class="card row mb-4 p-2 text-center bg-primary text-white">
            <h1>Qu'est-ce que EasyProf ?</h1>
        </div>
        <div class="row mb-2">
            <p>Chez EasyProf, nous comprenons les défis auxquels sont confrontés les professeurs lorsqu'ils décident de se
                lancer en tant qu'entrepreneurs. La complexité administrative, les formalités fiscales et les calculs
                fastidieux peuvent être décourageants. C'est pourquoi nous avons créé une plateforme conviviale et intuitive
                qui simplifie le processus de création d'auto-entreprise, vous permettant ainsi de vous concentrer sur ce
                que vous faites de mieux : enseigner.</p>

            <p>Notre équipe d'experts chevronnés vous guide étape par étape dans la création de votre auto-entreprise. Nous
                vous aidons à remplir les formulaires administratifs, à comprendre les obligations fiscales spécifiques aux
                professeurs et à optimiser votre structure d'entreprise pour bénéficier des avantages fiscaux les plus
                avantageux.</p>

            <p>L'un des principaux avantages de choisir EasyProf est notre expertise dans le domaine des crédits d'impôt et
                de l'avance immédiate. Nous vous expliquons en détail comment tirer parti de ces dispositifs fiscaux pour
                augmenter vos revenus de manière significative. Nous vous fournissons les outils nécessaires pour suivre et
                justifier vos dépenses professionnelles, garantissant ainsi que vous maximisez vos économies d'impôt tout en
                restant en conformité avec la législation fiscale en vigueur.</p>

            <p>Nous sommes fiers de notre approche personnalisée et de notre engagement à vous fournir un service de haute
                qualité. Notre équipe de support dédiée est toujours disponible pour répondre à vos questions, résoudre vos
                problèmes et vous guider tout au long de votre parcours entrepreneurial.</p>

            <p>Rejoignez EasyProf dès aujourd'hui et découvrez la simplicité de créer votre auto-entreprise en tant que
                professeur. Augmentez vos revenus, profitez des avantages fiscaux et concentrez-vous sur votre passion pour
                l'enseignement. Avec EasyProf, devenir entrepreneur n'a jamais été aussi facile !</p>
        </div>
        <div class="card row mb-4 p-2 text-center bg-primary text-white">
            <h1>Nos valeurs</h1>
            <h3>Chez EasyProf, nous nous engageons à défendre certaines valeurs fondamentales qui guident notre entreprise
                et notre relation avec nos clients.</h3>
            <div class="row p-4 text-justify">
                <ol>
                    <li>Juste rémunération : Nous croyons fermement à la valeur du travail des professeurs et à leur
                        contribution essentielle à la société. Nous nous efforçons donc de garantir une juste rémunération
                        pour
                        tous les professeurs qui choisissent notre plateforme. Nous les aidons à optimiser leurs revenus en
                        bénéficiant des avantages fiscaux disponibles, tout en respectant les normes et les obligations
                        légales.
                    </li>
                    <li>Facilité des démarches : Nous comprenons que le processus de création d'une auto-entreprise peut
                        être
                        intimidant et complexe. C'est pourquoi nous mettons tout en œuvre pour rendre les démarches simples
                        et
                        accessibles. Notre plateforme conviviale vous guide pas à pas, éliminant les tracas administratifs
                        et
                        vous permettant de créer votre auto-entreprise en un temps record. Nous sommes là pour vous
                        simplifier
                        la vie et vous aider à vous concentrer sur votre passion : l'enseignement.</li>
                    <li>Valeurs humanistes : Au cœur de notre entreprise se trouve une conviction profonde en faveur de
                        l'humanité. Nous croyons en l'importance de l'éducation pour le développement individuel et social.
                        Nous
                        soutenons les professeurs dans leur mission d'inspirer, d'éduquer et de former les générations
                        futures.
                        Nous croyons également en l'importance de relations humaines authentiques et bienveillantes. C'est
                        pourquoi nous offrons un service personnalisé et un support dédié, car nous valorisons chaque
                        professeur
                        et nous nous engageons à les accompagner tout au long de leur parcours entrepreneurial.</li>
                </ol>
            </div>
        </div>
        <div class="card row mb-4 p-2 text-center bg-primary text-white">
            <h1>Qui sommes-nous ?</h1>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="card flex-md-row mb-4 box-shadow h-md-250">
                    <img class="card-img-left flex-auto d-none d-md-block" data-src="holder.js/200x250?theme=thumb"
                        alt="portrait jean-loup becquet" style="width: 300px; height: 300px;"
                        src="../assets/portraitJLB.jpg" data-holder-rendered="true">
                    <div class="card-body d-flex flex-column align-items-start">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-0">
                                    <p class="text-dark" href="#">Jean-Loup BECQUET</p>
                                </h3>
                                <strong class="d-inline-block mb-2 text-primary">Associé gérant, professeur, CIO,
                                    developer</strong>
                            </div>
                            <div class="ml-4 d-md-none">
                                <img class="card-img-right flex-auto d-md-none d-block rounded-circle"
                                    data-src="holder.js/200x250?theme=thumb" alt="portrait jean-loup becquet"
                                    src="../assets/portraitJLB.jpg" data-holder-rendered="true"
                                    style="width: 100px; height: 100px;">
                            </div>
                        </div>
                        <p class="card-text mt-2 text-justify">Passionné d'informatique et de pédagogie, je souhaitais
                            mettre en oeuvre mes compétences afin de servir une cause qui me tiens à coeur : rendre
                            accessible à tous la possibilité de partager son savoir-faire et nourrir l'intelligence
                            collective !</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="card flex-md-row mb-4 box-shadow h-md-250">
                    <div class="card-body d-flex flex-column align-items-start align-items-md-end">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-0">
                                    <p class="text-dark text-md-right text-sm-left" href="#">Philippe TEIXEIRA</p>
                                </h3>

                                <strong class="d-inline-block mb-2 text-primary">Associé gérant, professeur,
                                    developer</strong>
                            </div>
                            <div class="ml-4 d-md-none">
                                <img class="card-img-right flex-auto d-md-none d-block rounded-circle"
                                    data-src="holder.js/200x250?theme=thumb" alt="portrait philippe teixeira"
                                    src="../assets/portraitPT.jpg" data-holder-rendered="true"
                                    style="width: 100px; height: 100px;">
                            </div>
                        </div>
                        <p class="card-text mt-2 text-justify">Cela fait maintenant plus de 10 ans que j'enseigne la musique
                            à domicile, mais cela n'a pas toujours été facile d'en faire son métier. Grâce à ma perséverence
                            et mes connaissances en administration, j'ai pu développer une solution crédible, viable et
                            avantageuse pour exercer cette profession. Et c'est cette expertise et ce savoir-faire que je
                            souhaite aujourd'hui mettre au service de milliers de professeurs comme moi !</p>
                    </div>
                    <img class="card-img-right flex-auto d-none d-md-block" data-src="holder.js/200x250?theme=thumb"
                        alt="portrait philippe teixeira" src="../assets/portraitPT.jpg" data-holder-rendered="true"
                        style="width: 300px; height: 300px;">
                </div>
            </div>
        </div>
    </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue';

import DefaultFooter from '../components/DefaultFooter.vue';

export default {
    beforeCreate: function () {
        document.body.className = 'homebody';
    },
    name: 'App',
    components: {
        DefaultHeader,
        DefaultFooter
    }
}
</script>