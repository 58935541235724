<template>
    <DefaultHeader/>
    <section class="bg-light faq-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="card card-style1 border-0">
                            <div class="card-body p-4 p-md-5 p-xl-6">
                                <div class="text-center mb-2-3 mb-lg-6">
                                    <span class="section-title text-primary">FAQ's</span>
                                    <h2 class="h1 mb-0 text-secondary">Frequently Asked Questions</h2>
                                </div>
                                <div id="accordion" class="accordion-style">
                                    <div class="row text-center">
                                        <FaqElement
                                        v-for="element in elements"
                                        :key="element.name"
                                        :question="element.question"
                                        :answer="element.answer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <DefaultFooter/>
  </template>
  
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import FaqElement from '../components/FaqElement.vue'

export default {
  beforeCreate: function() {
      document.body.className = 'homebody';
  },
  name: 'App',
  components: {
    DefaultHeader,
    FaqElement,
    DefaultFooter
  },
  data() {
    return {
      elements: [
        { question: "Comment est-ce que mes revenus vont augmenter ?", answer: "Pro ezez  ez ez ez ez ez ez ze ez ez e ze ze e ezfesseur de guitare"},
        { question: "Quelle aide puis-je trouver sur EasyProf ?", answer: "Profe ez ez ez e e a   g  e  er r re re re re re sseur d'anglais"},
        { question: "Pourquoi est-ce que je dois créer une auto-entreprise ?", answer: "Profe ez ez ez e e a   g  e  er r re re re re re sseur d'anglais"},
        { question: "Est-ce que EasyProf va m'aider dans la création de mon auto-entreprise ?", answer: "Profe ez ez ez e e a   g  e  er r re re re re re sseur d'anglais"},
        { question: "A quoi est-ce que je m'engage en créant mon auto-entreprise ?", answer: "Pre r re er er re er e r re re er er re re re re re re re re rere rerofesseur de Mathématique"},
        { question: "Comment sont gérées mes données personnelles ?", answer: "Profe ez ez ez e e a   g  e  er r re re re re re sseur d'anglais"}
        ]
    }
  }
}
</script>

<style>
.accordion-style .card {
    background: transparent;
    box-shadow: none;
    margin-bottom: 15px;
    margin-top: 0 !important;
    border: none;
}
.accordion-style .card:last-child {
    margin-bottom: 0;
}
.accordion-style .card-header {
    border: 0;
    background: none;
    padding: 0;
    border-bottom: none;
}
.accordion-style .btn-link {
    color: #ffffff;
    position: relative;
    background: #15395a;
    border: 1px solid #15395a;
    display: block;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: left;
    white-space: normal;
    box-shadow: none;
    padding: 15px 55px;
    text-decoration: none;
}
.accordion-style .btn-link:hover {
    text-decoration: none;
}
.accordion-style .btn-link.collapsed {
    background: #ffffff;
    border: 1px solid #15395a;
    color: #1e2022;
    border-radius: 3px;
}
.accordion-style .btn-link.collapsed:after {
    background: none;
    border-radius: 3px;
    content: "+";
    left: 16px;
    right: inherit;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    height: 26px;
    transform: none;
    width: 26px;
    top: 15px;
    text-align: center;
    background-color: #15395a;
    color: #fff;
}
.accordion-style .btn-link:after {
    background: #fff;
    border: none;
    border-radius: 3px;
    content: "-";
    left: 16px;
    right: inherit;
    font-size: 20px;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    transform: none;
    width: 26px;
    top: 15px;
    position: absolute;
    color: #15395a;
    text-align: center;
}
.accordion-style .card-body {
    padding: 20px;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #15395a;
    border-right: 1px solid #15395a;
    border-bottom: 1px solid #15395a;
}
@media screen and (max-width: 767px) {
    .accordion-style .btn-link {
        padding: 15px 40px 15px 55px;
    }
}
@media screen and (max-width: 575px) {
    .accordion-style .btn-link {
        padding: 15px 20px 15px 55px;
    }
}
.card-style1 {
    box-shadow: 0px 0px 10px 0px rgb(89 75 128 / 9%);
}
.border-0 {
    border: 0!important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.faq-section {
    padding: 120px 0;
    overflow: hidden;
    background: #fff;
}
.mb-2-3, .my-2-3 {
    margin-bottom: 2.3rem;
}

.section-title {
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
}
</style>