<template>
    <DefaultHeader/>
    <HomeNotConnected/>
    <DefaultFooter/>
  </template>
  
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import HomeNotConnected from '../components/HomeNotConnected/Index.vue'
import DefaultFooter from '../components/DefaultFooter.vue'

export default {
  beforeCreate: function() {
      document.body.className = 'homebody';
  },
  name: 'HomePage',
  components: {
    DefaultHeader,
    HomeNotConnected,
    DefaultFooter
  },
}
</script>
  