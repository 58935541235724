<template>
   <div class="container footercontainer">
      <footer class="pt-4 my-md-5 pt-md-5 border-top">
        <div class="row">
          <div class="col-12 col-md">
            <img class="mb-2" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="24" height="24">
            <small class="d-block mb-3 text-muted">&copy; 2017-2018</small>
          </div>
          <div class="col-6 col-md">
            <h5>Des questions ?</h5>
            <ul class="list-unstyled text-small">
              <li><router-link class="text-muted" to="/faq">FAQ</router-link></li>
              <li><router-link class="text-muted" to="/contact">Contact</router-link></li>
            </ul>
          </div>
          <div class="col-6 col-md">
            <h5>A propos</h5>
            <ul class="list-unstyled text-small">
              <li><router-link class="text-muted" to="/about">Qui sommes-nous</router-link></li>
              <li><router-link class="text-muted" to="/featuring">Offres</router-link></li>
              <li><router-link class="text-muted" to="/cgu">CGU</router-link></li>
            </ul>
          </div>
          <div class="col-6 col-md">
            <h5>Retrouvez-nous</h5>
            <ul class="list-unstyled text-small">
              <li><a class="text-muted" href="#"><font-awesome-icon :icon="['fab', 'square-instagram']" /> Instagram</a></li>
              <li><a class="text-muted" href="#"><font-awesome-icon :icon="['fab', 'square-facebook']" /> Facebook</a></li>
              <li><a class="text-muted" href="#"><font-awesome-icon :icon="['fab', 'square-twitter']" /> Twitter</a></li>
              <li><a class="text-muted" href="#"><font-awesome-icon :icon="['fab', 'linkedin']" /> LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
</template>


<style>
.footercontainer {
  max-width: 960px;
}
</style>