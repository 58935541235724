import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue.js'
import 'bootstrap/dist/js/bootstrap.bundle'
import * as VueRouter from 'vue-router'
import HomePage from './views/HomePage.vue'
import FeaturingPage from './views/FeaturingPage.vue'
import FaqPage from './views/FaqPage.vue'
import AboutPage from './views/AboutPage.vue'
import ContactPage from './views/ContactPage.vue'
import CGUPage from './views/CGUPage.vue'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faQuoteLeft, faStar, faInstagramSquare, faLinkedin, faFacebookSquare, faTwitterSquare, faUsers)

const router = VueRouter.createRouter({
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
    history: VueRouter.createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/featuring',
            name: 'FeaturingPage',
            component: FeaturingPage
        },
        {
            path: '/faq',
            name: 'FaqPage',
            component: FaqPage
        },
        {
            path: '/about',
            name: 'AboutPage',
            component: AboutPage
        },
        {
            path: '/contact',
            name: 'ContactPage',
            component: ContactPage
        },
        {
            path: '/cgu',
            name: 'CGUPage',
            component: CGUPage
        }
    ]
})

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.mount('#app')