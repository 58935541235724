<template>
    <HomeFeaturing/>
    <OtherCTA/>
    <TestimonialArea/>
    <RegisterCTA/>
</template>
  
<script>
import HomeFeaturing from './HomeFeaturing.vue'
import OtherCTA from './OtherCTA.vue'
import TestimonialArea from './TestimonialArea.vue'
import RegisterCTA from '../RegisterCTA.vue'


export default {
  name: 'HomeNotConnected',
  components: {
    HomeFeaturing,
    OtherCTA,
    TestimonialArea,
    RegisterCTA
  }
}
</script>
  