<template>
    <DefaultHeader />
    <div class="container col-8 d-flex justify-content-center">
        <div class="card p-4 box-shadow row col-6">
            <form class="col-12">
                <div class="container card mb-3 p-2 bg-primary text-center text-white">
                    <h3>Nous contacter</h3>
                </div>
                <div class="form-group mb-2" data-validate="Veuillez insérer un nom">
                    <input class="form-control" id="name" type="text" name="name" placeholder="Nom">
                    <label class="label-input" for="name"></label>
                </div>
                <div class="form-group mb-2" data-validate="Une adresse email valide est requise : ex@abc.xyz">
                    <input class="form-control" id="email" type="text" name="email" placeholder="Email">
                    <label class="label-input" for="email"></label>
                </div>
                <div class="form-group mb-2" data-validate="Veuillez entrer un message">
                    <textarea class="form-control" name="message" rows="6" placeholder="Votre message..."></textarea>
                </div>
                <div class="form-check mb-2 pl-0">
                    <input class="form-check-inline" id="ckb1" type="checkbox" name="copy-mail">
                    <label class="label-checkbox" for="ckb1">Envoyez-moi une copie de ce mail</label>
                </div>
                <div class="container">
                    <div class="row">
                        <button class="btn bg-primary">
                            Envoyer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue';

import DefaultFooter from '../components/DefaultFooter.vue';

export default {
    beforeCreate: function () {
        document.body.className = 'homebody';
    },
    name: 'ContactPage',
    components: {
        DefaultHeader,
        DefaultFooter
    }
}
</script>
